import React, { useState } from 'react';
import './Header.css';

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      });
    }
    if (isMobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  };

  return (
    <header className="header">
      <div className="header__logo">
        <img src="https://i.ibb.co/89bHtLy/logo.webp" alt="KuzyaCraft Logo" className="header__logo-img" />
        <span className="header__title">KuzyaCraft</span>
      </div>
      <nav className={`header__nav ${isMobileMenuOpen ? 'header__nav--open' : ''}`}>
        <button className="header__nav-link" onClick={() => scrollToSection('top')}>Начало</button>
        <button className="header__nav-link" onClick={() => scrollToSection('about')}>О нас</button>
        <button className="header__nav-link" onClick={() => scrollToSection('staff')}>Состав</button>
      </nav>
      <button className="header__hamburger" onClick={toggleMobileMenu}>
        &#9776; {/* Символ гамбургера лол */}
      </button>
    </header>
  );
};

export default Header;
