import React from 'react';
import BackButton from './BackButton';
import './RulesPage.css';
import '../App.css';

const RulesPage = () => {
  const rulesData = [
    { 
      title: '1 пункт', 
      rules: [
        '1.0 Запрещён обход Мута/Бана/Правил [ban]',
        '1.1 Запрещено попрошайничество [mute/warn]',
        '1.2 Упоминание участников/ролей просто так (everyone и here тоже считается) - [mute/warn]',
        '1.3 Запрещена реклама в любой форме [ban/mute]',
        '1.4 Запрещается мошенничество в любом виде [ban]',
        '1.5 Запрещено выдавать себя за другое лицо [mute/warn]',
        '1.6 Запрещены угрозы и запугивание [kick/mute/warn]',
        '1.7 Запрещено материться [mute/warn]',
        '1.7.1 Запрещено неадекватно себя вести в войсе [mute/ban/kick]',
        '1.8 Запрещено нарушать правила Discord а [ban]',
        '1.9 Запрещены оскорбительные никнеймы, которые нельзя упомянуть, а также, никнеймы и статусы',
        'с NFSW подтекстом, высмеиванием, унижением, провокацией. Запрещены аватарки и банеры',
        'с шокирующим контентом, NSFW контентом. Копировать профиль пользователя также запрещено! [warn/ban]'
      ] 
    },
    { 
      title: '2 пункт', 
      rules: [
        '2.0 Запрещено обсуждать политику, войну, действия Администрации и Модерации и т.д. [warn/mute]',
        '2.1 Неудовлетварительнве рофлы, оскорбления, неосторожные высказывания и т.п [ban/mute]',
        '2.2 Запрещено неадекватное поведение/провокация/дискриминация/дезинформация/политические споры [mute/warn]',
        '2.3 Запрещено устраивать бунт в любой форме [ban/kick/mute]',
        '2.4 Запрещено распространение личной информации без согласия [ban/kick/mute/warn]',
        '2.5 Запрещено распространение вирусов, читов и сторонних программ [ban/mute]',
        '2.6 Запрещена продажа вещей, ключей и т.д. [ban/kick/mute]',
        '2.7 Запрещён Флуд/Спам/Мультипост вне канала "Спам-флуд" [mute/warn]',
        '2.7.1 Запрещён оффтоп [mute/warn]',
        '2.8 Запрещена пропаганда Суицида/Фашизма/Расизма [ban/kick/mute]',
        '2.9 Запрещены оскорбления и переход на личность в любом виде [kick/mute]'
      ] 
    },
    {
      title: '3 пункт',
      rules: [
        '3.0 Запрещено распространение любого NSFW контента [ban]',
        '3.1 Запрещено нарушать правила с 1.0 по 3.0 [mute/warn/kick/ban]',
        '3.2 Запрещены Аморальные постройки [ban]',
        '3.3 Препятствование нормальной игре [warn/kick/ban]',
        '3.4 Запрещено использовать читы [ban]',
        '3.5 Злоупотребление полномочиями [kick/warn/ban/ЧСП]',
        '3.6 Неугодные метафоры, сравнения, эпитеты и прочее [mute/kick/warn/ban]',
        '3.7 Покушение ( И удачное, и неудачное ) на сервер [ЧСП]',
        '3.8 Запрещено гриферство [ban/ЧСП]',
        '3.9 Всего выдаётся 3 предупреждения [ban/ЧСП]'
      ]
    },
    {
      title: '4 пункт',
      rules: [
        '4.1 Нельзя взлоупотрерблять правами [ban/снятие/на усмотрение гл. админа]',
        '4.2 Нельзя без причины банить/кикать/мутить [на усмотрение гл. админа]',
        '4.3 Правила не являются исключением и тоже влияют на администрацию',
        '4.4 Категорически запрещено как-либо пытаться крашнуть сервер [на усмотрение владельца/ЧСП]',
        '4.5 Администрация должна передавать тикеты по поводу технических проблем администратору @skippy или @кот кузя (может измениться список)',
        '4.6 Гриферство от имени администрации категорически запрещено [на усмотрение владельца/ЧСП] '
    ]
    }
  ];

  const penaltiesData = [
    'Наказания указаны около правила',
    'Всего выдается 3 (три) предупреждения, далее - ЧСП',
    'С возможностью выноса или без, на усмотрение владельца.',
    'Последнее изменение: 05.06.2024.'
  ];

  return (
    <div className="App">
      <div className="rules-page main-content">

        <h2>Правила сервера</h2>
        {rulesData.map((section, index) => (
          <div key={index} className="rules-section">
            <h3>{section.title}</h3>
            <ul>
              {section.rules.map((rule, idx) => (
                <li key={idx}>{rule}</li>
              ))}
            </ul>
          </div>
        ))}
        <div className="penalties-section">
          <h3>Пункты наказания</h3>
          <ul>
            {penaltiesData.map((penalty, index) => (
              <li key={index}>{penalty}</li>
            ))}
          </ul>
        </div>
        <BackButton />
      </div>
    </div>
  );
};

export default RulesPage;
