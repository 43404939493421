import React from 'react';
import './About.css';

const About = () => {
  const serverIP = "kuzyacraft.aternos.me";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(serverIP);
    alert(`IP адрес скопирован: ${serverIP}`);
  };

  return (
    <section id="about" className="about">
      <h2 className="about__title">О сервере</h2>
      <p className="about__description">
        KuzyaCraft - это уникальный Minecraft сервер, где вы можете наслаждаться игрой с друзьями, участвовать в увлекательных мероприятиях и открывать новые миры.
      </p>
      <div className="about__info">
        <div className="about__ip-box">
          <span className="about__ip-address">{serverIP}</span>
          <button className="about__button" onClick={copyToClipboard}>Скопировать IP</button>
        </div>
      </div>
    </section>
  );
};

export default About;
//это уникальный Minecraft сервер, где вы можете наслаждаться игрой с друзьями, участвовать в увлекательных мероприятиях и открывать новые миры.
