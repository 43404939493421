import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Service.css';
import { ReactComponent as RulesIcon } from './icons/rules.svg';

const Rules = () => {
  const navigate = useNavigate();

  return (
    <div className="service" onClick={() => navigate('/rules')} id="rules">
      <div className="service__content">
        <RulesIcon className="service__icon" />
        <h2 className="service__title">Правила</h2>
        <p className="service__description">Ознакомьтесь с правилами нашего сервера.</p>
      </div>
    </div>
  );
};

export default Rules;
