import React from 'react';
import BackButton from './BackButton';
import './BlacklistPage.css';
import '../App.css';

const BlacklistPage = () => {
    const blacklistData = [
    { name: 'Кот Барсик', ruleViolated: '3.7, 3.4, 3.2, 3.1 и все правила с 1.0 по 3.0', dateAdded: '08.10.2023', dateRemoved: 'Без выноса' },
    { name: 'Серый кот/Егор', ruleViolated: '4.4, 4.1, 4.1, 4.2, 2.2, 2.3, 1.6, 1.8 и 2.4', dateAdded: '27.06.2024', dateRemoved: 'Без выноса' },
  ];

  return (
    <div className="App">
      <div className="blacklist-page main-content">
        <h2>Черный список</h2>
        <table className="blacklist-table">
          <thead>
            <tr>
              <th>Ник</th>
              <th>Нарушенные правила</th>
              <th>Дата занесения</th>
              <th>Дата выноса</th>
            </tr>
          </thead>
          <tbody>
            {blacklistData.map((entry, index) => (
              <tr key={index}>
                <td>{entry.name}</td>
                <td>{entry.ruleViolated}</td>
                <td>{entry.dateAdded}</td>
                <td>{entry.dateRemoved}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <BackButton />

      </div>
    </div>
  );
};

export default BlacklistPage;
