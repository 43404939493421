import React from 'react';
import './Service.css';
import { ReactComponent as DiscordIcon } from './icons/discord.svg';

const DiscordServer = () => {
  return (
    <a href="https://discord.gg/RzYr5jdzeN" target="_blank" rel="noopener noreferrer" className="service-link">
      <section className="service" id="discord">
        <div className="service__content">
          <DiscordIcon className="service__icon" />
          <h2 className="service__title">Дискорд сервер</h2>
          <p className="service__description">Присоединяйтесь к нашему Дискорд серверу, чтобы быть в курсе всех новостей и событий.</p>
        </div>
      </section>
    </a>
  );
};

export default DiscordServer;
