// src/components/DonatePage.js
import React from 'react';
import BackButton from './BackButton';
import './DonatePage.css';
import '../App.css'; // Подключение общего дизайна

const DonatePage = () => {
  const donationOptions = [
    { name: 'VIP', price: '15 руб.', description: 'Доступ к VIP функциям.', color: '#FDE414' },
    { name: 'Креатив', price: '30 руб.', description: 'Доступ к функциям  Креатива.', color: '#57E512' },
    { name: 'Модератор', price: '39 руб.', description: 'Доступ к функциям Модератора.', color: '#12D7E5' },
    { name: 'Админ', price: '49 руб.', description: 'Доступ к функциям Админа.', color: '#E51212' },
    { name: 'Король', price: '149 руб.', description: 'Доступ к функциям Короля.', color: '#F2F306' },
    { name: 'Энигма', price: '199 руб.', description: 'Доступ к функциям Энигма.', color: '#E106F3' },
    { name: 'Deluxe', price: '259 руб.', description: 'Доступ к Deluxe функциям.', color: '#F38E06' },
    { name: 'Основатель', price: '538 руб.', description: 'Доступ к функциям Основателя.', color: '#06F3A6' }
  ];

  const handleDonationClick = () => {
    window.open('https://www.donationalerts.com/r/az09za900090', '_blank');
  };

  return (
    <div className="App">
      <div className="donate-page main-content">
        <h2>Гайд по донату</h2>
        <div className="donation-options">
          {donationOptions.map((option, index) => (
            <div key={index} className="donation-card" style={{ backgroundColor: option.color }}>
              <h3>{option.name}</h3>
              <p>{option.price}</p>
              <p>{option.description}</p>
            </div>
          ))}
        </div>
        <button className="donate-button" onClick={handleDonationClick}>Перейти на Donation Alerts</button>
        <div className="donation-instructions">
          <h1>1. Выберите донат из карточек выше.</h1>
          <h1>2. Перейдите на Donation Alerts и введите сумму и свой Minecraft ник.</h1>
          <img src="https://i.ibb.co/8BN9YWL/image.png" alt='гайд №1' className="donation-guide-image" />
          <h1>3. Готово! После доната напишите Администратору @sk1pppy или @catkuzya. (теги Дискорд)</h1>
          <h1>Если есть проблемы - пишите в поддержку.</h1>
          <a href="https://discord.gg/pDDU4mYW3a">Поддержка</a>
        </div>
        <BackButton />
      </div>
    </div>
  );
};

export default DonatePage;
