import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Service.css';
import { ReactComponent as DonateIcon } from './icons/donate.svg';

const Donate = () => {
  const navigate = useNavigate();

  return (
    <div className="service" onClick={() => navigate('/donate')} id="donate">
      <div className="service__content">
        <DonateIcon className="service__icon" />
        <h2 className="service__title">Донат</h2>
        <p className="service__description">Поддержите наш сервер и получите бонусы.</p>
      </div>
    </div>
  );
};

export default Donate;
