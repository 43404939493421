import React from 'react';
import './Staff.css';

const owners = [
  {
    avatar: 'https://i.ibb.co/2SGW8j9/avatar2.png',
    name: 'skippy',
    role: 'Владелец',
    description: 'Отвечает за поддержку, помощь игрокам на сервере и помогает с обслуживанием сервера.'
  },
  {
    avatar: 'https://i.ibb.co/mFN1ZP1/avatar1.webp',
    name: 'кот Кузя',
    role: 'Владелец',
    description: 'Основатель сервера KuzyaCraft. Следит за всем процессом и развитием.'
  },
  {
    avatar: 'https://i.ibb.co/3yRrpYB/avatar3.webp',
    name: 'Kotekaaa',
    role: 'Владелец',
    description: 'Следит за правилами и справедливой игрой на сервере.'
  }
];
const admins = [
    {
      avatar: 'https://i.ibb.co/JHjzn6q/avatar4.png',
      name: 'its.squwzee',
      role: 'Администратор',
      description: 'Отвечает за поднятие актива и ивенты.'
    }
  ];

const Staff = () => {
    return (
      <section id="staff" className="staff">
        <h2 className="staff__title">Владельцы</h2>
        <div className="staff__cards">
          {owners.map((member, index) => (
            <div key={index} className="staff__card">
              <img src={member.avatar} alt={`${member.name}'s avatar`} className="staff__avatar" />
              <h3 className="staff__name">{member.name}</h3>
              <p className="staff__role">{member.role}</p>
              <p className="staff__description">{member.description}</p>
            </div>
          ))}
        </div>
        <h2 className="staff__title">Администраторы</h2>
        <div className="staff__cards">
          {admins.map((member, index) => (
            <div key={index} className="staff__card">
              <img src={member.avatar} alt={`${member.name}'s avatar`} className="staff__avatar" />
              <h3 className="staff__name">{member.name}</h3>
              <p className="staff__role">{member.role}</p>
              <p className="staff__description">{member.description}</p>
            </div>
          ))}
        </div>
      </section>
    );
  };
  
  export default Staff;