import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import About from './components/About';
import Staff from './components/Staff';
import Footer from './components/Footer';
import Rules from './components/Rules';
import Donate from './components/Donate';
import Support from './components/Support';
import Blacklist from './components/Blacklist';
import DiscordServer from './components/DiscordServer';
import BlacklistPage from './components/BlacklistPage';
import RulesPage from './components/RulesPage';
import DonatePage from './components/DonatePage';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <main className="main-content" id="top">
                <div className="main-background"></div>
                <div className="main-content-text">
                  <div className="main-title">KuzyaCraft</div>
                  <div className="main-description">Добро пожаловать на наш сервер! Присоединяйтесь к нам для незабываемых приключений.</div>
                </div>
              </main>
              <About />
              <Staff />
              <div className="services">
                <Rules />
                <Donate />
                <Support />
                <Blacklist />
                <DiscordServer />
              </div>
            </>
          } />
          <Route path="/blacklist" element={<BlacklistPage />} />
          <Route path="/rules" element={<RulesPage />} />
          <Route path="/donate" element={<DonatePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
