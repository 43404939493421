import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Service.css';
import { ReactComponent as BlacklistIcon } from './icons/blacklist.svg';

const Blacklist = () => {
  const navigate = useNavigate();

  return (
    <div className="service" onClick={() => navigate('/blacklist')} id="blacklist">
      <div className="service__content">
        <BlacklistIcon className="service__icon" />
        <h2 className="service__title">Черный список</h2>
        <p className="service__description">Здесь находятся пользователи, нарушившие правила сервера и попавшие в черный список.</p>
      </div>
    </div>
  );
};

export default Blacklist;
