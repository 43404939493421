import React, { useState } from 'react';
import './Service.css';
import { ReactComponent as SupportIcon } from './icons/support.svg';

const Support = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div onClick={handleOpenModal} className="service-link">
        <section className="service" id="support">
          <div className="service__content">
            <SupportIcon className="service__icon" />
            <h2 className="service__title">Техническая поддержка</h2>
            <p className="service__description">Возникли проблемы или вопросы? Обратитесь за помощью к нашей службе поддержки!</p>
          </div>
        </section>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
            <h2>Техническая поддержка</h2>
            <p>Пожалуйста, перейдите на наш <a href="https://discord.gg/RzYr5jdzeN" target="_blank" rel="noopener noreferrer">Дискорд сервер</a> и создайте тикет для получения помощи.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Support;
